var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { forkJoin, of } from 'rxjs';
import { finalize, catchError, tap, map } from 'rxjs/operators';
import * as _ from 'lodash';
// Services
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { ServiceDetailState } from './../../state/service-detail.state';
import { ServiceDetailService } from './../service-detail/service-detail.service';
// Utils
import { getBudgetFormat, prepareBudget } from '../../utils/format-budget.utils';
// Extends Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { CurrentUserService } from '@app/core/services/current-user/current-user.service';
var ServiceDetailFacadeService = /** @class */ (function (_super) {
    __extends(ServiceDetailFacadeService, _super);
    function ServiceDetailFacadeService(state, httpService, toastr, currentUserService) {
        var _this = _super.call(this, currentUserService) || this;
        _this.state = state;
        _this.httpService = httpService;
        _this.toastr = toastr;
        _this.currentUserService = currentUserService;
        return _this;
    }
    Object.defineProperty(ServiceDetailFacadeService.prototype, "serviceId", {
        get: function () {
            return this._serviceId;
        },
        set: function (value) {
            this._serviceId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceDetailFacadeService.prototype, "selectedService", {
        get: function () {
            return this._selectedService;
        },
        set: function (value) {
            this._selectedService = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceDetailFacadeService.prototype, "statusService", {
        get: function () {
            return this.selectedService.attributes.status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceDetailFacadeService.prototype, "serviceCompleted", {
        get: function () {
            return this.statusService === 'completed';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceDetailFacadeService.prototype, "isHomeService", {
        get: function () {
            return this.selectedService && this.selectedService.attributes.home_service;
        },
        enumerable: true,
        configurable: true
    });
    ServiceDetailFacadeService.prototype.reset = function () {
        this.serviceId = null;
        this.selectedService = null;
        this.state.reset();
    };
    ServiceDetailFacadeService.prototype.getService = function () {
        var _this = this;
        this.state.set('loadingSub', true);
        return this.httpService.getService(this.serviceId).pipe(tap(function (service) { return (_this.selectedService = service); }), finalize(function () { return _this.state.set('loadingSub', false); }));
    };
    ServiceDetailFacadeService.prototype.loadService = function (isUpdate) {
        var _this = this;
        if (isUpdate === void 0) { isUpdate = false; }
        this.state.set('loadingSub', true);
        this.httpService
            .getService(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function (service) {
            _this.selectedService = service;
            _this.state.set('serviceSub', service);
            _this.state.set('loadedSub', true);
            if (!isUpdate) {
                _this.loadVisits();
                _this.loadBudgets();
                _this.loadComments();
                _this.loadFiles();
                _this.loadHistoric();
                _this.loadHoldData();
                _this.loadComplaints();
                if (!_this.isProvider()) {
                    _this.getSpace(service.attributes.space_id);
                }
                if (service.attributes.additional_works_enabled) {
                    _this.loadAdditionalWorks();
                }
                if (_this.isHomeService) {
                    _this.loadHomeServiceChecklists();
                }
                if (_this.isOperator()) {
                    _this.loadNotes();
                    _this.loadBreaches();
                    _this.loadRelatedServices();
                    if (_this.serviceCompleted) {
                        _this.loadRepays();
                        _this.loadRecharges();
                    }
                }
                if (_this.isProvider() && _this.serviceCompleted) {
                    _this.loadRecharges();
                }
                if (_this.isClient() && _this.serviceCompleted) {
                    _this.loadRepays();
                }
            }
        });
    };
    ServiceDetailFacadeService.prototype.updateService = function (data) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .updateService(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Servicio actualizado correctamente');
            _this.loadService(false);
        }, function () {
            _this.toastr.error('Error al actualizar el servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.documentationRejectService = function (data) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .documentationRejectService(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Estado del servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el estado servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.documentationConfirmService = function () {
        var _this = this;
        var data = {};
        this.state.set('loadingSub', true);
        this.httpService
            .documentationConfirmService(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Estado del servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el estado servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.finalizeService = function (params) {
        var _this = this;
        var data = __assign({}, params);
        this.state.set('loadingSub', true);
        this.httpService
            .finalizeService(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Estado del servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el estado servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.completeService = function (params) {
        var _this = this;
        var data = __assign({}, params);
        this.state.set('loadingSub', true);
        this.httpService
            .completeService(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Estado del servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el estado servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.rejectionService = function (data) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .rejectionService(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Servicio rechazado');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al rechazar el servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.authorizationService = function () {
        var _this = this;
        var data = { service_authorization: { dummy: true } };
        this.state.set('loadingSub', true);
        this.httpService
            .authorizationService(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Estado del servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el estado servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.reOpeningService = function () {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .reOpeningService(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Estado del servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el estado servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.updateFacilityManagerTransfer = function (data) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .updateFacilityManagerTransfer(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.updateNetworkManagerTransfer = function (data) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .updateNetworkManagerTransfer(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Servicio actualizado correctamente');
            _this.loadService(true);
        }, function () {
            _this.toastr.error('Error al actualizar el servicio, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.updateProviderTransfer = function (data) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .updateProviderTransfer(this.serviceId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Servicio actualizado correctamente');
            _this.loadService(false);
        }, function (error) {
            var errors = error.error.errors;
            _this.toastr.error(errors.provider_id[0]);
        });
    };
    ServiceDetailFacadeService.prototype.getInvoiceService = function (id) {
        var _this = this;
        return this.httpService.getInvoiceService(id).pipe(catchError(function () {
            _this.toastr.error('Error al descargar la factura, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.getAmendmentInvoiceService = function (id) {
        var _this = this;
        return this.httpService.getAmendmentInvoiceService(id).pipe(catchError(function () {
            _this.toastr.error('Error al descargar la factura, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.getReceiptService = function (id) {
        var _this = this;
        return this.httpService.getReceiptService(id).pipe(catchError(function () {
            _this.toastr.error('Error al descargar la factura, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.getAmendmentReceiptService = function (id) {
        var _this = this;
        return this.httpService.getAmendmentReceiptService(id).pipe(catchError(function () {
            _this.toastr.error('Error al descargar la factura, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.getAcquittanceService = function (id) {
        var _this = this;
        return this.httpService.getAcquittanceService(id).pipe(catchError(function () {
            _this.toastr.error('Error al descargar la factura, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.getChecklistService = function (id) {
        var _this = this;
        return this.httpService.getChecklistService(id).pipe(catchError(function () {
            _this.toastr.error('Error al descargar la gama, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.getGroundingCertificate = function (id) {
        var _this = this;
        return this.httpService.getGroundingCertificate(id).pipe(catchError(function () {
            _this.toastr.error('Error al descargar el certificado de puesta a tierra, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.loadVisits = function () {
        var _this = this;
        this.state.set('loadingVisitsSub', true);
        this.httpService
            .getVisits(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingVisitsSub', false); }), tap(function (visits) {
            var status = '';
            if (visits && visits.length > 0) {
                var visitPending = visits.find(function (visit) { return visit.attributes.status === 'pending'; });
                if (visitPending) {
                    status = visitPending.attributes.started_at
                        ? visitPending.attributes.started_at
                        : visitPending.attributes.starts_at;
                }
                else {
                    status = 'Finalizada';
                }
            }
            else {
                status = 'No programada';
            }
            _this.state.set('visitStatusSub', status);
        }))
            .subscribe(function (visits) {
            _this.state.set('visitsSub', visits);
        });
    };
    ServiceDetailFacadeService.prototype.createVisit = function (data) {
        var _this = this;
        this.state.set('loadingVisitsSub', true);
        this.httpService.createVisit(this.serviceId, data).subscribe(function () {
            _this.toastr.success('Visita creada correctamente');
            _this.loadVisits();
            _this.loadService(false);
        }, function () {
            _this.toastr.error('Error al crear la visita, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.updateVisit = function (visitID, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.httpService.updateVisit(this.serviceId, visitID, params).subscribe(function () {
            _this.toastr.success('Visita actualizada correctamente');
            _this.loadVisits();
            _this.loadFiles();
            _this.loadService(false);
        }, function () {
            _this.toastr.error('Error al actualizar la visita, por favor pruebe en unos minutos.');
            _this.loadFiles();
        });
    };
    ServiceDetailFacadeService.prototype.finishVisit = function (visitID, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.httpService.finishVisit(this.serviceId, visitID, params).subscribe(function () {
            _this.toastr.success('Visita finalizada correctamente');
            _this.loadVisits();
            _this.loadFiles();
            _this.loadService(false);
        }, function () {
            _this.toastr.error('Error al finalizar la visita, por favor pruebe en unos minutos.');
            _this.loadFiles();
        });
    };
    ServiceDetailFacadeService.prototype.getServicePdfVisit = function (visitId) {
        var _this = this;
        return this.httpService.getServicePdfVisit(this.serviceId, visitId).pipe(catchError(function () {
            _this.toastr.error('Error al descargar el parte de trabajo, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.loadBudgets = function () {
        var _this = this;
        var listAssessments = [];
        this.state.set('loadingBudgetsSub', true);
        this.httpService
            .getServiceBudgets(this.serviceId)
            .subscribe(function (budgets) { return __awaiter(_this, void 0, void 0, function () {
            var budgetsSort, _i, budgetsSort_1, budget;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        budgetsSort = budgets.map(function (item) { return item.id; });
                        _i = 0, budgetsSort_1 = budgetsSort;
                        _a.label = 1;
                    case 1:
                        if (!(_i < budgetsSort_1.length)) return [3 /*break*/, 4];
                        budget = budgetsSort_1[_i];
                        return [4 /*yield*/, this.httpService
                                .getServiceBudget(this.serviceId, budget)
                                .toPromise()
                                .then(function (response) {
                                listAssessments.push(prepareBudget(response));
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.state.set('budgetsSub', listAssessments);
                        this.state.set('loadingBudgetsSub', false);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ServiceDetailFacadeService.prototype.loadBudget = function (budgetId) {
        var _this = this;
        this.httpService
            .getServiceBudget(this.serviceId, budgetId)
            .subscribe(function (response) {
            var budget = prepareBudget(response);
            _this.setSelectedBudget(getBudgetFormat(budget.assessment, budget.items));
        });
    };
    ServiceDetailFacadeService.prototype.loadAdditionalWork = function (additionalWork) {
        var _this = this;
        this.httpService
            .getAdditionalWorkInfo(this.serviceId, additionalWork)
            .then(function (response) {
            var budget = prepareBudget(response);
            _this.setSelectedBudget(getBudgetFormat(budget.assessment, budget.items));
        });
    };
    ServiceDetailFacadeService.prototype.getServicePdfAssesment = function (assesmentId, type, additionalWork) {
        var _this = this;
        return this.httpService
            .getServicePdfAssesment(this.serviceId, assesmentId, type, additionalWork)
            .pipe(catchError(function () {
            _this.toastr.error('Error al descargar el presupuesto, por favor pruebe en unos minutos');
            return of(null);
        }));
    };
    ServiceDetailFacadeService.prototype.createServiceBudget = function (body) {
        var _this = this;
        this.state.set('loadingBudgetsSub', true);
        this.httpService.createServiceBudget(this.serviceId, body).subscribe(function (response) {
            _this.loadBudget(response.data.id);
            _this.toastr.success('Presupuesto creado correctamente');
        }, function () {
            _this.toastr.error('Error al actualizar el presupuesto, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.updateServiceBudget = function (budgetId, body, reloadBudgets, reloadService) {
        var _this = this;
        if (reloadBudgets === void 0) { reloadBudgets = false; }
        if (reloadService === void 0) { reloadService = false; }
        this.state.set('loadingBudgetsSub', true);
        this.httpService
            .updateServiceBudget(this.serviceId, budgetId, body)
            .pipe(finalize(function () { return _this.state.set('loadingBudgetsSub', false); }))
            .subscribe(function () {
            if (reloadBudgets) {
                _this.loadBudgets();
            }
            else {
                _this.loadBudget(budgetId);
            }
            if (reloadService) {
                _this.loadService(true);
            }
            _this.toastr.success('Presupuesto actualizada correctamente');
        }, function () {
            _this.toastr.error('Error al actualizar el presupuesto, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.setSelectedBudget = function (budget) {
        this.state.set('selectedBudgetSub', budget);
    };
    ServiceDetailFacadeService.prototype.getSelectedBudget = function () {
        return this.state.get('selectedBudgetSub');
    };
    ServiceDetailFacadeService.prototype.confirmationBudget = function (budgetId) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .confirmationBudget(this.serviceId, budgetId)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.loadBudgets();
            _this.loadService(true);
            _this.toastr.success('Presupuesto enviado a revisión de Famaex');
        }, function () {
            _this.toastr.error('Error al enviar revisión del presupuesto, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.rejectionBudget = function (budgetId, data) {
        var _this = this;
        this.state.set('loadingSub', true);
        this.httpService
            .rejectionBudget(this.serviceId, budgetId, data)
            .pipe(finalize(function () { return _this.state.set('loadingSub', false); }))
            .subscribe(function () {
            _this.toastr.success('Presupuesto rechazado');
            _this.loadBudgets();
        }, function () {
            _this.toastr.error('Error al rechazar el presupuesto, por favor pruebe en unos minutos.');
        });
    };
    ServiceDetailFacadeService.prototype.loadComments = function () {
        var _this = this;
        this.state.set('loadingCommentsSub', true);
        this.httpService
            .getServiceComments(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingCommentsSub', false); }))
            .subscribe(function (comments) {
            _this.state.set('commentsSub', comments);
        });
    };
    ServiceDetailFacadeService.prototype.createComment = function (data) {
        var _this = this;
        this.state.set('loadingCommentsSub', true);
        this.httpService
            .createServiceComment(this.serviceId, data)
            .subscribe(function () {
            _this.loadComments();
        });
    };
    ServiceDetailFacadeService.prototype.deleteComment = function (commentId) {
        var _this = this;
        this.state.set('loadingCommentsSub', true);
        this.httpService.deleteComment(this.serviceId, commentId).subscribe(function () {
            _this.loadComments();
        });
    };
    ServiceDetailFacadeService.prototype.loadFiles = function () {
        var _this = this;
        this.state.set('loadingFilesSub', true);
        this.httpService
            .getServiceFiles(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingFilesSub', false); }))
            .subscribe(function (files) {
            _this.state.set('filesSub', files);
        });
    };
    ServiceDetailFacadeService.prototype.getServiceFile = function (fileId) {
        return this.httpService.getServiceFile(this.serviceId, fileId);
    };
    ServiceDetailFacadeService.prototype.createServiceImage = function (body) {
        return this.httpService.createServiceImage(this.serviceId, body);
    };
    ServiceDetailFacadeService.prototype.createServiceDocument = function (body) {
        return this.httpService.createServiceDocument(this.serviceId, body);
    };
    ServiceDetailFacadeService.prototype.deleteServiceImage = function (imageId) {
        var _this = this;
        this.httpService
            .deleteServiceImage(this.serviceId, imageId)
            .subscribe(function () { return _this.loadFiles(); });
    };
    ServiceDetailFacadeService.prototype.deleteServiceFile = function (fileId) {
        var _this = this;
        this.httpService
            .deleteServiceFile(this.serviceId, fileId)
            .subscribe(function () { return _this.loadFiles(); });
    };
    ServiceDetailFacadeService.prototype.deleteVisitImage = function (visitId, imageId) {
        var _this = this;
        this.httpService
            .deleteVisitImage(this.serviceId, visitId, imageId)
            .subscribe(function () { return _this.loadFiles(); });
    };
    ServiceDetailFacadeService.prototype.deleteVisitFile = function (visitId, fileId) {
        var _this = this;
        this.httpService
            .deleteVisitFile(this.serviceId, visitId, fileId)
            .subscribe(function () { return _this.loadFiles(); });
    };
    ServiceDetailFacadeService.prototype.loadNotes = function () {
        var _this = this;
        this.state.set('loadingNotesSub', true);
        this.httpService
            .getServiceNotes(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingNotesSub', false); }))
            .subscribe(function (notes) {
            _this.state.set('notesSub', notes);
        });
    };
    ServiceDetailFacadeService.prototype.createServiceNote = function (body) {
        return this.httpService.createServiceNote(this.serviceId, body);
    };
    ServiceDetailFacadeService.prototype.createServiceNoteSuccess = function () {
        this.toastr.success('Nota creada correctamente');
        this.loadNotes();
    };
    ServiceDetailFacadeService.prototype.updateServiceNote = function (noteId, params) {
        return this.httpService.updateServiceNote(this.serviceId, noteId, params);
    };
    ServiceDetailFacadeService.prototype.updateServiceNoteSuccess = function () {
        this.toastr.success('Nota actualizada correctamente');
        this.loadNotes();
    };
    ServiceDetailFacadeService.prototype.loadHoldData = function () {
        return this.httpService.loadHoldData(this.serviceId).pipe(map(function (response) { return response && response.data; }), map(function (data) {
            // tslint:disable-next-line:underscore-consistent-invocation
            var sortedData = _.sortBy(data, 'id');
            return ((sortedData &&
                sortedData.length > 0 &&
                sortedData[sortedData.length - 1]) ||
                null);
        }));
    };
    ServiceDetailFacadeService.prototype.updateFrozenService = function (data) {
        var _this = this;
        return this.httpService.updateFrozenService(this.serviceId, data).pipe(tap(function () {
            _this.loadService(true);
        }));
    };
    ServiceDetailFacadeService.prototype.frozenService = function (data) {
        var _this = this;
        return this.httpService.frozenService(this.serviceId, data).pipe(tap(function () {
            _this.loadService(true);
        }));
    };
    ServiceDetailFacadeService.prototype.unfrozenService = function () {
        var _this = this;
        return this.httpService.unfrozenService(this.serviceId).pipe(tap(function () {
            _this.loadService(true);
        }));
    };
    ServiceDetailFacadeService.prototype.loadBreaches = function () {
        var _this = this;
        this.state.set('loadingBreachesSub', true);
        this.httpService
            .getServiceBreaches(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingBreachesSub', false); }))
            .subscribe(function (breaches) {
            _this.state.set('breachesSub', breaches);
        });
    };
    ServiceDetailFacadeService.prototype.loadComplaints = function () {
        var _this = this;
        this.state.set('loadingComplaintsSub', true);
        this.httpService
            .getServiceComplaints(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingComplaintsSub', false); }))
            .subscribe(function (complaints) {
            return _this.state.set('complaintsSub', complaints);
        });
    };
    ServiceDetailFacadeService.prototype.loadHistoric = function () {
        var _this = this;
        this.state.set('loadingHistoricSub', true);
        this.httpService.expandObservable = true;
        var listHistoric = [];
        this.httpService
            .getServiceHistoric(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingHistoricSub', false); }))
            .subscribe(function (logs) {
            listHistoric.push.apply(listHistoric, logs.data);
            if (logs.meta.current_page === logs.meta.last_page ||
                logs.meta.last_page === 0) {
                _this.httpService.expandObservable = false;
                _this.state.set('historicSub', listHistoric);
            }
        });
    };
    ServiceDetailFacadeService.prototype.loadRepays = function () {
        var _this = this;
        this.state.set('loadingRepaysSub', true);
        this.httpService
            .getServiceRepays(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingRepaysSub', false); }))
            .subscribe(function (repays) {
            _this.state.set('repaysSub', repays);
        });
    };
    ServiceDetailFacadeService.prototype.createRepay = function (data) {
        return this.httpService.createRepayService(this.serviceId, data);
    };
    ServiceDetailFacadeService.prototype.updateRepay = function (repayId, data) {
        return this.httpService.updateRepayService(this.serviceId, repayId, data);
    };
    ServiceDetailFacadeService.prototype.deleteRepay = function (repayId) {
        var _this = this;
        this.httpService
            .deleteRepayService(this.serviceId, repayId)
            .subscribe(function () { return _this.loadRepays(); });
    };
    ServiceDetailFacadeService.prototype.loadRecharges = function () {
        var _this = this;
        this.state.set('loadingRechargesSub', true);
        this.httpService
            .getServiceRecharge(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingRechargesSub', false); }))
            .subscribe(function (recharges) {
            _this.state.set('rechargesSub', recharges);
        });
    };
    ServiceDetailFacadeService.prototype.createRecharges = function (data) {
        return this.httpService.createRechargeService(this.serviceId, data);
    };
    ServiceDetailFacadeService.prototype.updateRecharge = function (rechargeId, data) {
        return this.httpService.updateRechargeService(this.serviceId, rechargeId, data);
    };
    ServiceDetailFacadeService.prototype.deleteRecharge = function (rechargeId) {
        var _this = this;
        this.httpService
            .deleteRechargeService(this.serviceId, rechargeId)
            .subscribe(function () { return _this.loadRecharges(); });
    };
    ServiceDetailFacadeService.prototype.loadRelatedServices = function () {
        var _this = this;
        var serviceDetail = [];
        if (this.selectedService.attributes.parent_service_id) {
            serviceDetail.push(this.httpService.getService(this.selectedService.attributes.parent_service_id));
        }
        else if (this.selectedService.relationships.linked_services.data) {
            this.selectedService.relationships.linked_services.data.forEach(function (service) {
                serviceDetail.push(_this.httpService.getService(service.id));
            });
        }
        // tslint:disable-next-line: deprecation
        forkJoin.apply(void 0, serviceDetail).subscribe(function (relatedServices) {
            _this.state.set('relatedServicesSub', relatedServices);
        });
    };
    ServiceDetailFacadeService.prototype.loadAdditionalWorks = function () {
        var _this = this;
        this.state.set('loadingBudgetsSub', true);
        var listAssessments = [];
        this.httpService
            .getServiceAdditionalWorks(this.serviceId)
            .subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var additionalWorkSort, _i, additionalWorkSort_1, additionalWork;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        additionalWorkSort = data.map(function (item) { return item.id; });
                        _i = 0, additionalWorkSort_1 = additionalWorkSort;
                        _a.label = 1;
                    case 1:
                        if (!(_i < additionalWorkSort_1.length)) return [3 /*break*/, 4];
                        additionalWork = additionalWorkSort_1[_i];
                        return [4 /*yield*/, this.httpService
                                .getAdditionalWorkInfo(this.serviceId, additionalWork)
                                .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                var assessment;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            assessment = {
                                                assessment: __assign({}, response.data.attributes, { id: response.data.id }),
                                                items: response.included,
                                                payment: null
                                            };
                                            if (!this.assessmentHasPayment(response)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.httpService
                                                    .getPaymentInfo(this.serviceId, response.data.id, response.data.attributes.last_payment_id)
                                                    .then(function (payment) {
                                                    assessment = __assign({}, assessment, { payment: payment });
                                                })];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2:
                                            listAssessments.push(assessment);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.state.set('additionalWorksSub', listAssessments);
                        this.state.set('loadingBudgetsSub', false);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ServiceDetailFacadeService.prototype.deleteAdditionalWork = function (additionalWorkId) {
        var _this = this;
        this.httpService
            .deleteAdditionalWorkInfo(this.serviceId, additionalWorkId)
            .subscribe(function () { return _this.loadAdditionalWorks(); });
    };
    ServiceDetailFacadeService.prototype.getSpace = function (spaceId) {
        var _this = this;
        this.httpService
            .getSpace(spaceId)
            .subscribe(function (data) { return _this.state.set('spaceOnServiceSub', data.data); });
    };
    ServiceDetailFacadeService.prototype.loadHomeServiceChecklists = function () {
        var _this = this;
        this.state.set('loadingHomeServiceChecklistsSub', true);
        this.httpService
            .getHomeServiceChecklists(this.serviceId)
            .pipe(finalize(function () { return _this.state.set('loadingHomeServiceChecklistsSub', false); }))
            .subscribe(function (homeServiceChecklists) {
            _this.state.set('homeServiceChecklistsSub', homeServiceChecklists);
        });
    };
    ServiceDetailFacadeService.prototype.updateHomeServiceChecklist = function (checklistID, params) {
        if (params === void 0) { params = {}; }
        return this.httpService.updateHomeServiceChecklist(this.serviceId, checklistID, params);
    };
    ServiceDetailFacadeService.prototype.assessmentHasPayment = function (assessment) {
        return (assessment &&
            assessment.data &&
            assessment.data.attributes.last_payment_id);
    };
    return ServiceDetailFacadeService;
}(CurrentUserFacadeService));
export { ServiceDetailFacadeService };
